body{
    margin: 0;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #F2F2F2;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #6D0185;
  }